@import url(https://fonts.googleapis.com/css2?family=Source+Sans+Pro:ital,wght@0,200;0,300;0,400;0,600;1,200;1,300&display=swap);
.styles_floatingWhatsapp__XqCEJ {
  width: 100%;
  height: 100%;
  text-align: left;
  position: relative;
}

.styles_whatsappButton__1tdNV {
  width: 60px;
  height: 60px;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  position: fixed;
  bottom: 2rem;
  right: 2rem;
  cursor: pointer;
  background-color: #25d366;
  border-radius: 50%;
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
  box-shadow: rgb(0 0 0 / 15%) 0px 4px 12px 0px;
}

.styles_whatsappButton__1tdNV:after {
  content: '';
  border-color: inherit;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  position: absolute;
  opacity: 0;
  -webkit-animation-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
          animation-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  -webkit-animation-duration: 1.2s;
          animation-duration: 1.2s;
  -webkit-animation-name: styles__pulse__1K0ME;
          animation-name: styles__pulse__1K0ME;
  -webkit-animation-iteration-count: infinite;
          animation-iteration-count: infinite;
  -webkit-animation-delay: 1s;
          animation-delay: 1s;
}

.styles_whatsappButton__1tdNV svg {
  fill: #fff;
  padding: 0.5rem;
  width: 55px;
  height: 55px;
}

.styles_whatsappChatBox__2ewFK {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  border-radius: 18px;
  overflow: hidden;
  background-color: #fff;
  touch-action: auto;
  position: fixed;
  bottom: 7rem;
  right: 4rem;
  width: 375px;
  opacity: 0;
  transition: height 0.2s ease-out;
  box-shadow: 0 4px 12px 0 rgb(0 0 0 / 50%);
}

.styles_whatsappChatBox__2ewFK.styles_open__Fm1Nl {
  -webkit-animation-name: styles__bounceIn__2NNtX;
          animation-name: styles__bounceIn__2NNtX;
  -webkit-animation-duration: 400ms;
          animation-duration: 400ms;
  transition-timing-function: ease-out;
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
}

.styles_whatsappChatBox__2ewFK.styles_close__1-he3 {
  -webkit-animation-name: styles__bounceOut__3w2Ot;
          animation-name: styles__bounceOut__3w2Ot;
  -webkit-animation-duration: 600ms;
          animation-duration: 600ms;
  transition-timing-function: ease-in;
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
}

@media (min-width: 320px) and (max-width: 480px) {
  .styles_whatsappChatBox__2ewFK {
    right: 0;
    left: 0;
    max-width: 90%;
    margin: auto;
  }
}

.styles_chatHeader__2Gq9a {
  background-color: #075e54;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  margin-bottom: auto;
  min-height: 65px;
  padding: 0.25rem;
}

.styles_chatHeader__2Gq9a .styles_avatar__2jCBf {
  padding: 0.25rem;
  max-width: 20%;
  position: relative;
}
.styles_chatHeader__2Gq9a .styles_avatar__2jCBf img {
  vertical-align: middle;
  border-radius: 50%;
  height: 60px;
  width: 60px;
  border: 1px solid rgba(255, 255, 255, 0.473);
}

.styles_chatHeader__2Gq9a .styles_avatar__2jCBf:after {
  content: '';
  width: 10px;
  height: 10px;
  background: #4fbe86;
  border: 1px solid #fff;
  position: absolute;
  border-radius: 50%;
  bottom: 5px;
  right: 8px;
}

.styles_chatHeader__2Gq9a .styles_avatar__2jCBf svg {
  fill: #fff;
  vertical-align: middle;
}

.styles_chatHeader__2Gq9a .styles_status__WCT3e {
  color: #fff;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  width: 70%;
  padding: 0.5rem;
}

.styles_chatHeader__2Gq9a .styles_statusTitle__2fqVb {
  font-size: 16px;
  font-weight: 700;
}

.styles_chatHeader__2Gq9a .styles_statusSubtitle__2brRG {
  font-size: 80%;
  margin-top: 0.25px;
  color: #f0f0f0;
}

.styles_chatHeader__2Gq9a .styles_close__1-he3 {
  padding: 1rem;
  display: -webkit-flex;
  display: flex;
  cursor: pointer;
}

.styles_chatHeader__2Gq9a .styles_close__1-he3 svg {
  fill: #fff;
  width: 24px;
  height: 24px;
}

.styles_chatBody__2mV1t {
  padding: 20px;
  background-size: cover;
  background-repeat: repeat;
  max-height: 382px;
  background-color: #e5ddd5;
  height: 100%;
  opacity: 0.9;
}

.styles_chatBubble__1NbsA {
  background-color: #e6f8f1;
  padding: 16px 28px;
  border-radius: 20px;
  border-bottom-left-radius: 2px;
  display: inline-block;
}

.styles_typing__3PunU {
  -webkit-align-items: center;
          align-items: center;
  display: -webkit-flex;
  display: flex;
  height: 17px;
}

.styles_typing__3PunU .styles_dot__3_qT2 {
  -webkit-animation: styles_typingAnimation__2O97o 1.8s infinite ease-in-out;
          animation: styles_typingAnimation__2O97o 1.8s infinite ease-in-out;
  background-color: rgba(20, 105, 69, 0.7);
  border-radius: 50%;
  height: 7px;
  margin-right: 4px;
  vertical-align: middle;
  width: 7px;
  display: inline-block;
}

.styles_typing__3PunU .styles_dot__3_qT2:nth-child(1) {
  -webkit-animation-delay: 200ms;
          animation-delay: 200ms;
}
.styles_typing__3PunU .styles_dot__3_qT2:nth-child(2) {
  -webkit-animation-delay: 300ms;
          animation-delay: 300ms;
}
.styles_typing__3PunU .styles_dot__3_qT2:nth-child(3) {
  -webkit-animation-delay: 400ms;
          animation-delay: 400ms;
}
.styles_typing__3PunU .styles_dot__3_qT2:last-child {
  margin-right: 0;
}

.styles_chatBody__2mV1t .styles_message__1x9BQ {
  padding: 7px 14px 6px;
  background-color: #fff;
  border-radius: 0px 8px 8px;
  position: relative;
  margin-top: 4px;
  max-width: calc(100% - 120px);
  z-index: 2;
  box-shadow: rgb(0 0 0 / 13%) 0px 1px 0.5px;
}

.styles_chatBody__2mV1t .styles_accountName__1Bv0n {
  font-size: 13px;
  font-weight: 700;
  line-height: 18px;
  color: rgb(17 17 17 / 50%);
}

.styles_chatBody__2mV1t .styles_messageBody__21R08 {
  font-size: 14px;
  line-height: 19px;
  margin-top: 4px;
  color: #111111;
  white-space: pre-wrap;
}

.styles_chatBody__2mV1t .styles_message__1x9BQ .styles_triangle__2qEH4 {
  display: inline-block;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 20px 20px 0;
  border-color: transparent #fff transparent transparent;
  position: absolute;
  left: -10px;
  top: 0;
}

.styles_chatBody__2mV1t .styles_messageTime__2LJop {
  display: -webkit-flex;
  display: flex;
  margin-top: 4px;
  font-size: 12px;
  line-height: 16px;
  color: rgb(17 17 17 / 50%);
  -webkit-justify-content: flex-end;
          justify-content: flex-end;
}

.styles_chatFooter__3RMzm {
  margin-top: auto;
}

.styles_chatFooter__3RMzm form {
  display: -webkit-flex;
  display: flex;
  padding: 8px;
  -webkit-align-items: center;
          align-items: center;
  background-color: #f0f0f0;
}

.styles_chatFooter__3RMzm form .styles_input__1fTyr {
  border-radius: 20px;
  padding: 0 10px;
  border: none;
  background-color: #fefefe;
  width: 100%;
  min-height: 45px;
  transition: box-shadow 0.2s ease-in-out;
}

.styles_chatFooter__3RMzm form .styles_input__1fTyr:focus {
  border-color: #075e54;
  outline: 0;
  box-shadow: 0 0 0 1px rgb(7 94 84 / 50%);
}

.styles_chatFooter__3RMzm form .styles_input__1fTyr::-webkit-input-placeholder {
  color: #949494;
}

.styles_chatFooter__3RMzm form .styles_input__1fTyr:-ms-input-placeholder {
  color: #949494;
}

.styles_chatFooter__3RMzm form .styles_input__1fTyr::placeholder {
  color: #949494;
}

.styles_chatFooter__3RMzm form .styles_buttonSend__3Jhmp {
  -webkit-flex: 0 1;
          flex: 0 1;
  padding: 8px;
  background-color: transparent;
  border: 0;
  cursor: pointer;
}

.styles_chatFooter__3RMzm form .styles_buttonSend__3Jhmp svg {
  vertical-align: middle;
  fill: #919191;
}

.styles_chatFooter__3RMzm form .styles_input__1fTyr.styles_arabic__5-mxe {
  font-family: tahoma;
}

.styles_chatFooter__3RMzm form .styles_buttonSend__3Jhmp:disabled {
  pointer-events: none;
  opacity: 0.5;
}

/* dark mode */
.styles_dark__32udc .styles_chatBody__2mV1t {
  background-color: #0d1418;
}

.styles_dark__32udc .styles_chatBody__2mV1t .styles_message__1x9BQ {
  background-color: #056162;
  box-shadow: rgb(0 0 0 / 70%) 0px 1px 0.5px;
}

.styles_dark__32udc .styles_chatBody__2mV1t .styles_accountName__1Bv0n {
  color: rgb(255 255 255 / 50%);
}

.styles_dark__32udc .styles_chatBody__2mV1t .styles_messageBody__21R08 {
  color: rgb(241 241 242 / 90%);
}

.styles_dark__32udc .styles_chatBody__2mV1t .styles_message__1x9BQ .styles_triangle__2qEH4 {
  border-color: transparent #056162 transparent transparent;
}

.styles_dark__32udc .styles_chatBody__2mV1t .styles_messageTime__2LJop {
  color: rgb(255 255 255 / 50%);
}

.styles_dark__32udc .styles_chatFooter__3RMzm form {
  background-color: #1e2428;
}

.styles_dark__32udc .styles_chatFooter__3RMzm form .styles_input__1fTyr {
  background-color: #33383b;
  color: #f1f1f2;
}

.styles_dark__32udc .styles_chatFooter__3RMzm form .styles_input__1fTyr:focus {
  border-color: #0da896;
  outline: 0;
  box-shadow: 0 0 0 1px rgb(13 168 150 / 50%);
}

.styles_dark__32udc .styles_chatFooter__3RMzm form .styles_buttonSend__3Jhmp svg {
  fill: rgb(104, 104, 104);
}

.styles_dark__32udc .styles_chatBubble__1NbsA {
  background-color: #32373a;
}

.styles_dark__32udc .styles_typing__3PunU .styles_dot__3_qT2 {
  background-color: rgba(46, 148, 106, 0.7);
}

/* keyframes */
@-webkit-keyframes styles__pulse__1K0ME {
  0% {
    box-shadow: 0 0 0 0 #25d366;
    opacity: 1;
  }

  100% {
    box-shadow: 0 0 0 15px #25d366;
    opacity: 0;
  }
}
@keyframes styles__pulse__1K0ME {
  0% {
    box-shadow: 0 0 0 0 #25d366;
    opacity: 1;
  }

  100% {
    box-shadow: 0 0 0 15px #25d366;
    opacity: 0;
  }
}

@-webkit-keyframes styles__bounceIn__2NNtX {
  0% {
    opacity: 0;
    -webkit-transform: scale(0, 0);
            transform: scale(0, 0);
    -webkit-transform-origin: bottom right;
            transform-origin: bottom right;
  }

  50% {
    -webkit-transform: scale(1.03, 1.03);
            transform: scale(1.03, 1.03);
    -webkit-transform-origin: bottom right;
            transform-origin: bottom right;
  }

  100% {
    opacity: 1;
    -webkit-transform: scale(1, 1);
            transform: scale(1, 1);
    -webkit-transform-origin: bottom right;
            transform-origin: bottom right;
  }
}

@keyframes styles__bounceIn__2NNtX {
  0% {
    opacity: 0;
    -webkit-transform: scale(0, 0);
            transform: scale(0, 0);
    -webkit-transform-origin: bottom right;
            transform-origin: bottom right;
  }

  50% {
    -webkit-transform: scale(1.03, 1.03);
            transform: scale(1.03, 1.03);
    -webkit-transform-origin: bottom right;
            transform-origin: bottom right;
  }

  100% {
    opacity: 1;
    -webkit-transform: scale(1, 1);
            transform: scale(1, 1);
    -webkit-transform-origin: bottom right;
            transform-origin: bottom right;
  }
}

@-webkit-keyframes styles__bounceOut__3w2Ot {
  0% {
    opacity: 1;
    height: scale(1, 1);
    -webkit-transform-origin: bottom right;
            transform-origin: bottom right;
  }
  100% {
    opacity: 0;
    -webkit-transform: scale(0, 0);
            transform: scale(0, 0);
    -webkit-transform-origin: bottom right;
            transform-origin: bottom right;
  }
}

@keyframes styles__bounceOut__3w2Ot {
  0% {
    opacity: 1;
    height: scale(1, 1);
    -webkit-transform-origin: bottom right;
            transform-origin: bottom right;
  }
  100% {
    opacity: 0;
    -webkit-transform: scale(0, 0);
            transform: scale(0, 0);
    -webkit-transform-origin: bottom right;
            transform-origin: bottom right;
  }
}

@-webkit-keyframes styles_typingAnimation__2O97o {
  0% {
    -webkit-transform: translateY(0px);
            transform: translateY(0px);
    background-color: rgba(20, 105, 69, 0.7);
  }
  28% {
    -webkit-transform: translateY(-7px);
            transform: translateY(-7px);
    background-color: rgba(20, 105, 69, 0.4);
  }
  44% {
    -webkit-transform: translateY(0px);
            transform: translateY(0px);
    background-color: rgba(20, 105, 69, 0.2);
  }
}

@keyframes styles_typingAnimation__2O97o {
  0% {
    -webkit-transform: translateY(0px);
            transform: translateY(0px);
    background-color: rgba(20, 105, 69, 0.7);
  }
  28% {
    -webkit-transform: translateY(-7px);
            transform: translateY(-7px);
    background-color: rgba(20, 105, 69, 0.4);
  }
  44% {
    -webkit-transform: translateY(0px);
            transform: translateY(0px);
    background-color: rgba(20, 105, 69, 0.2);
  }
}

.styles_notificationIndicator__2fv8U {
  border-radius: 50%;
  font-size: 11px;
  font-weight: bold;
  color: white;
  background-color: #ff5454;
  padding: 3px;
  text-align: center;
  line-height: 14px;
  width: 14px;
  height: 14px;
  position: absolute;
  top: -5px;
  right: 0px;
}

* {
  margin: 0;
  padding: 0;
}

body {
  height: 100vh;
  margin: 0;
  font-family: 'Source Sans Pro', sans-serif, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#root {
  height: 100%;
}

::-webkit-scrollbar {
  width: 8px;
  background: transparent;
}
::-webkit-scrollbar-thumb {
  background-color: #c5c5c5;
  border-radius: 6px;
}

@media screen and (max-width: 600px) {
  body {
    height: 100vh;
  }

  #root {
    height: 100%;
  }
}
