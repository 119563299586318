@import url('https://fonts.googleapis.com/css2?family=Source+Sans+Pro:ital,wght@0,200;0,300;0,400;0,600;1,200;1,300&display=swap');

* {
  margin: 0;
  padding: 0;
}

body {
  height: 100vh;
  margin: 0;
  font-family: 'Source Sans Pro', sans-serif, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#root {
  height: 100%;
}

::-webkit-scrollbar {
  width: 8px;
  background: transparent;
}
::-webkit-scrollbar-thumb {
  background-color: #c5c5c5;
  border-radius: 6px;
}

@media screen and (max-width: 600px) {
  body {
    height: 100vh;
  }

  #root {
    height: 100%;
  }
}